import React from "react"
import Fade from "react-reveal/Fade"

const FeatureSection = () => {
  return (
    <div className="max-w-7xl mx-auto ">
      <Fade bottom cascade>
        <div className="mt-10 px-8">
          <h1 className="text-white text-7xl font-bold xxs:text-2xl xs:text-3xl sm:text-5xl lg:text-7xl">
            Keep your learnings in one place
          </h1>
          <h2 className="text-white text-lg mt-5 opacity-50">
            If you are like me a Dev/Product creator who loves to learn new
            things everyday, you probably have a lot of notes in different
            places.
          </h2>
        </div>
      </Fade>
      <Fade bottom cascade>
        <div className="mt-6 flex flex-row justify-space xxs:flex-col xs:flex-col sm:flex-row">
          <div className="w-1/3 bg-gradient-to-r from-pink to-purple p-8 rounded-xl m-5 xxs:w-full xs:w-full sm:w-1/3">
            <h1 className="text-black font-semibold text-2xl">
              Save your new learning <br></br> with one click
            </h1>
            <p className="mt-10 text-black font-semibold opacity-50 text-sm">
              Open DevJournal.ai and save your new learning super fast. Just write
              your note, add some media, maybe a label also and hit save.
            </p>
          </div>

          <div className="w-1/3 bg-lightblack p-8 rounded-xl m-5 xxs:w-full xs:w-full sm:w-1/3">
            <h1 className="text-white text-2xl">
              Get Daily recap <br></br> with the help of our AI
            </h1>
            <p className="mt-10 text-white opacity-50 text-sm">
              At the end of the day you will get a recap of your notes and
              highlights. You are able to customize the recap to better
              align with what you want to remember.
            </p>
          </div>
          <div className="w-1/3 bg-lightblack p-8 rounded-xl m-5 xxs:w-full xs:w-full sm:w-1/3">
            <h1 className="text-white text-2xl">
              Don't let another week go by without journaling
            </h1>
            <p className="mt-10 text-white opacity-50 text-sm">
              You have access to your daily recaps for the entire week and all previous ones.
              Be aware, mindfull and proud about your weekly progress.
            </p>
          </div>
        </div>
      </Fade>
    </div>
  )
}

export default FeatureSection
