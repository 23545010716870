import * as React from "react"
import Layout from "../components/layout"
import { Helmet } from 'react-helmet';

// Components
import Header from "../components/Home/header"
import FeatureSection from "../components/Home/featureSection"

const IndexPage = () => {
  return (
    <div className="h-auto w-screen">
      <Helmet>
        <title>DevJournal.ai</title>
        <meta name="description" content="DevJournal.ai combines journaling with AI to help developers and product creators enhance productivity and keep their learnings in one place."></meta>
      </Helmet>

      <Layout>
        <Header></Header>
        <FeatureSection></FeatureSection>
      </Layout>
    </div>
    )
}

export default IndexPage
